<template>
  <section>
    <main class="uk-container">
      <div class="title-p uk-margin-xlarge-top">
        <p>Você escolhe, a gente desenha</p>
      </div>
      <div
        class="uk-child-width-expand uk-child-width-1-2@s uk-text-center uk-margin-medium-top"
        uk-grid
        uk-height-match="target: > div > .uk-card"
      >
        <div v-for="(item, index) in title" :key="index">
          <div class="uk-card-choose uk-card-choose-tablet uk-card-body">
            <div class="card-img">
              <img :src="headerImg" alt="" />
            </div>
            <div class="card-text">
              {{ item }}
            </div>
            <template v-if="index === 0">
              <span class="card-SubText">{{ SubTitle }}</span>
            </template>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import img_card from './img_card.svg';

export default {
  name: 'HeaderVue',
  data() {
    return {
      headerImg: img_card,
      title: ['Material Gráfico', 'Artes Digitais & Redes Sociais'],
      SubTitle: '(Impressão)'
    };
  }
};
</script>

<style scoped>
.title-p {
  height: 35px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  line-height: 35.2px;
  font-size: 32px;
  color: #000000;
}

.uk-card-choose {
  width: 511px;
  height: 150px;
  border-radius: 12px;
  background-color: #f4f8fb;
}

.card-text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.card-SubText {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000cc;
}

@media (max-width: 641px) {
  .uk-card-choose {
    margin-left: -10px;
    width: 125px;
  }

  .title-p {
    font-size: 22px;
  }

  .card-text {
    font-size: 16px;
  }

  .card-SubText {
    font-size: 14px;
  }
}

@media (min-width: 641px) and (max-width: 960px) {
  .uk-card-choose-tablet {
    margin-left: -10px;
    width: 225px;
  }
}
</style>