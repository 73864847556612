import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/LoginView.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword/ForgotPasswordView.vue')
  },
  {
    path: '/forgot-password/step-one',
    name: 'ForgotPasswordStepOne',
    component: () =>
      import('../components/Login/ForgotPassword/ForgotPasswordStepOne.vue')
  },
  {
    path: '/forgot-password/step-two',
    name: 'ForgotPasswordStepTwo',
    component: () =>
      import('../components/Login/ForgotPassword/ForgotPasswordStepTwo.vue')
  },
  {
    path: '/forgot-password/step-three',
    name: 'ForgotPasswordStepThree',
    component: () =>
      import('../components/Login/ForgotPassword/ForgotPasswordStepThree.vue')
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: () => import('../views/CreateAccount/CreateAccountView.vue')
  },
  {
    path: '/create-account/step-one',
    name: 'CreateAccountStepOne',
    component: () =>
      import('../components/Login/CreateAccount/CreateAccountStepOne.vue')
  },
  {
    path: '/create-account/step-two',
    name: 'CreateAccountStepTwo',
    component: () =>
      import('../components/Login/CreateAccount/CreateAccountStepTwo.vue')
  },
  {
    path: '/create-account/confirm-email',
    name: 'CreateAccountConfirmEmail',
    component: () =>
      import('../components/Login/CreateAccount/CreateAccountConfirmEmail.vue')
  },
  {
    path: '/contato',
    name: 'ContactUs',
    component: () => import('../views/ContactUs/ContactUsView.vue')
  },
  {
    path: '/carreiras',
    name: 'WorkWithUs',
    component: () => import('../views/WorkWithUs/WorkWithUsView.vue')
  },
  {
    path: '/sobre',
    name: 'About',
    component: () => import('../views/About/AboutView.vue')
  },
  {
    path: '/termos-de-uso',
    name: 'Terms',
    component: () => import('../views/Terms/TermsView.vue')
  },
  {
    path: '/politica-de-privacidade',
    name: 'PrivacyPolices',
    component: () => import('../views/PrivacyPolices/PrivacyPolicesView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
