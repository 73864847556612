<template>
  <footer>
    <section class="uk-section uk-section-small">
      <div class="uk-container">
        <div class="uk-child-width-expand@m" uk-grid>
          <div v-for="(section, index) in footerSections" :key="index">
            <h4 @click="toggleSection(index)">
              {{ section.title }}
              <span v-if="isMobileOrTablet" class="toggle-icon">
                <img
                  :src="section.iconArrow"
                  alt=""
                  class="arrow-icon"
                  :class="{ rotated: !section.collapsed }"
                />
              </span>
            </h4>
            <ul
              v-show="!section.collapsed || !isMobileOrTablet"
              class="uk-list"
            >
              <li v-for="(item, idx) in section.items" :key="idx">
                <a :href="item.href">
                  <img
                    v-if="item.icon"
                    :src="item.icon"
                    alt=""
                    class="social-icon"
                  />
                  <span class="text-footer">{{ item.text }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import IconLinkedin from './IconLinkedin.svg';
import IconInstagram from './IconInstagram.svg';
import IconFacebook from './IconFacebook.svg';
import ArrowIcon from './icon.svg';

export default {
  name: 'FooterComponent',
  data() {
    return {
      isMobileOrTablet: false,
      footerSections: [
        {
          title: 'Siga-nos',
          iconArrow: ArrowIcon,
          items: [
            { text: 'Instagram', href: '#', icon: IconInstagram },
            { text: 'Facebook', href: '#', icon: IconFacebook },
            { text: 'LinkedIn', href: '#', icon: IconLinkedin }
          ],
          collapsed: true
        },
        {
          title: 'Arte Legal',
          iconArrow: ArrowIcon,
          items: [
            { text: 'Sobre', href: '/sobre' },
            { text: 'Blog', href: '#' },
            { text: 'Trabalhe Conosco', href: '/carreiras' }
          ],
          collapsed: true
        },
        {
          title: 'Atendimento',
          iconArrow: ArrowIcon,
          items: [
            { text: 'Fale Conosco', href: '/contato' },
            { text: 'Ajuda & Suporte', href: '#' }
          ],
          collapsed: true
        },
        {
          title: 'Termos & Políticas',
          iconArrow: ArrowIcon,
          items: [
            {
              text: 'Política de Privacidade',
              href: '/politica-de-privacidade'
            },
            { text: 'Termos de Uso', href: '/termos-de-uso' }
          ],
          collapsed: true
        }
      ]
    };
  },
  mounted() {
    this.checkIsMobileOrTablet();
    window.addEventListener('resize', this.checkIsMobileOrTablet);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIsMobileOrTablet);
  },
  methods: {
    toggleSection(index) {
      if (this.isMobileOrTablet) {
        this.footerSections[index].collapsed =
          !this.footerSections[index].collapsed;
      }
    },
    checkIsMobileOrTablet() {
      this.isMobileOrTablet = window.innerWidth <= 1024;
    }
  }
};
</script>

<style scoped>
footer {
  background-color: #ffffff;
  padding: 40px 0;
}

h4 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
  color: #000000;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-footer {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}

.uk-list {
  padding-left: 0;
}

.uk-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.uk-list a {
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.uk-list a:hover {
  text-decoration: underline;
}

.social-icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.toggle-icon {
  display: none;
}

.arrow-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  transition: transform 0.3s;
}

.arrow-icon.rotated {
  transform: rotate(180deg);
}

@media (max-width: 1024px) {
  .toggle-icon {
    display: inline;
  }
}
</style>
