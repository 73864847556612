<template>
  <section class="bg-section uk-margin-large-top">
    <main class="uk-container">
      <div class="uk-text-center uk-margin-large-top uk-margin-large-bottom">
        <h2 class="title-h2">
          <span>Crie sua arte legal</span>
        </h2>
        <div class="uk-margin-medium-top">
          <span class="subTitle"
            >Tenha conexões com os melhores <br class="uk-hidden@m" />
            profissionais hoje.</span
          >
        </div>
        <div class="uk-margin-medium-top">
          <button class="button-enter">Entrar</button>
        </div>
      </div>
    </main>
  </section>
</template>
<script>
export default {
  name: 'RecentWorks'
};
</script>

<style scoped>
.bg-section {
  background-color: #ffda79;
  padding: 100px 0;
}

.title-h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 56px;
  line-height: 61.6px;
  color: #000000;
  margin: 0;
}

.subTitle {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000cc;
  margin: 0;
}

.button-enter {
  border: 2px solid #ffffff;
  border-radius: 12px;
  height: 48px;
  width: 226px;
  background-color: #ffda79;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 641px) {
  .title-h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 35.2px;
    color: #000000;
    margin: 0;
  }
}
</style>