<template>
  <section>
    <main class="uk-container uk-margin-large-top">
      <div class="uk-text-center">
        <h2 class="title-h2">
          <span class="title-part">Por que escolhem o </span>
          <span class="title-part">Arte Legal ? </span>
        </h2>
      </div>
      <div
        class="uk-child-width-1-2@s uk-margin-top"
        uk-grid
        uk-height-match="target: > div > .uk-card"
      >
        <div class="margin-mb-top">
          <div
            v-for="(item, index) in subtitleTextPairs"
            :key="index"
            class="uk-card uk-card-body"
          >
            <span class="title-text">{{ item.title }}</span>
            <div class="width-subtitle">
              <span class="subtitle-text">{{ item.subtitle }}</span>
            </div>
          </div>
        </div>
        <div class="uk-visible@s">
          <div class="">
            <img
              class="uk-margin-medium-top uk-margin-mb-img"
              :src="img_choose"
              alt=""
            />
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import img_choose from './img_choose.svg';

export default {
  name: 'ChooseArteLegal',
  data() {
    return {
      img_choose: img_choose,
      subtitleTextPairs: [
        {
          title: 'Seja atendido de onde estiver',
          subtitle: 'Reduzimos distância com nosso atendimento online.'
        },
        {
          title: 'Escolha seu Projeto',
          subtitle:
            'Nossa comunidade de designers está pronta para atender você.'
        },
        {
          title: 'R$ 0 taxas de contratação',
          subtitle:
            'Facilitamos o processo de contratação, você agenda seu horário e '
        }
      ]
    };
  }
};
</script>

<style scoped>
.uk-card-body {
  padding: 40px 0px;
}
.title-h2 {
  font-family: 'Roboto Serif', sans-serif;
  font-weight: 500;
  font-size: 56px;
  line-height: 61.6px;
  color: #000000;
}

.title-part {
  display: block;
}

.title-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  font-family: 'Inter', sans-serif;
  color: #000000;
}

.subtitle-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  color: #000000cc;
}

@media (min-width: 641px) and (max-width: 960px) {
  .title-part {
    display: inline;
  }
}

@media (max-width: 767px) {
  .title-h2 {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    line-height: 38.4px;
    font-weight: 700;
  }

  .title-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }

  .subtitle-text {
    font-size: 16px;
    align-items: left;
    font-weight: 400;
    line-height: 24px;
  }

  .uk-card-body {
    display: flow-root;
    padding: 15px 0px;
  }
}
</style>
