<template>
  <section>
    <main class="uk-container uk-margin-large-top">
      <div class="uk-text-center">
        <h2 class="title-h2">
          <span
            >Trabalhos <br class="uk-hidden@s" />
            Recentes</span
          >
        </h2>
      </div>

      <div class="uk-margin-large-top">
        <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match" uk-grid>
          <div v-for="(img, index) in limitedImgWorks" :key="index">
            <img :src="img" alt="" @click="openModal(img)" />
          </div>
        </div>
      </div>
    </main>

    <div id="image-modal" uk-modal>
      <div class="uk-modal-dialog modal-container">
        <button class="uk-modal-close-default" type="button" uk-close />
        <div class="modal-content">
          <img :src="selectedImage" alt="" class="modal-image" />
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import img_works_1 from './img_works_1.svg';
import img_works_2 from './img_works_2.svg';
import img_works_3 from './img_works_3.svg';
import img_works_4 from './img_works_4.svg';
import img_works_5 from './img_works_5.svg';
import img_works_6 from './img_works_6.svg';
import UIkit from 'uikit';

export default {
  name: 'RecentWorks',
  data() {
    return {
      imgWorks: [
        img_works_1,
        img_works_2,
        img_works_3,
        img_works_4,
        img_works_5,
        img_works_6
      ],
      selectedImage: null,
      isMobile: window.innerWidth <= 767
    };
  },
  computed: {
    limitedImgWorks() {
      return this.isMobile ? this.imgWorks.slice(0, 3) : this.imgWorks;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    openModal(img) {
      this.selectedImage = img;
      UIkit.modal('#image-modal').show();
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 767;
    }
  }
};
</script>


<style scoped>
.title-h2 {
  font-family: 'Roboto Serif', sans-serif;
  font-weight: 500;
  font-size: 56px;
  line-height: 61.6px;
  color: #000000;
}

.uk-margin-large-top {
  margin-top: 70px;
}

.modal-container {
  width: 720px;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  padding: 0;
  margin: auto;
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

@media (max-width: 767px) {
  .uk-margin-large-top {
    margin-top: 30px;
  }

  .title-h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38.4px;
    color: #000000;
  }
}
</style>

