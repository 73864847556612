<template>
  <section style="background-color: #fafafa">
    <main class="uk-container uk-margin-large-top">
      <div
        class="uk-grid-large uk-child-width-1-2@m uk-child-width-1-1@s uk-margin-xlarge-top uk-margin-xlarge-bottom"
        uk-grid
      >
        <div>
          <p class="tx-title-mb uk-hidden@m">Por que o Arte Legal?</p>
          <p class="tx-title">
            O Arte legal está há três anos criando arte para você.
          </p>
          <button class="button-enter uk-margin-top uk-visible@m">
            Entrar
          </button>
        </div>
        <div class="uk-grid-small uk-child-width-expand@m" uk-grid>
          <div v-for="(stat, index) in stats" :key="index">
            <div class="uk-text-number uk-text-center uk-text-right@m">
              {{ stat.number }}
            </div>
            <div class="uk-text-label uk-text-center uk-text-right@m">
              {{ stat.label }}
            </div>
          </div>
          <button class="button-enter uk-text-center uk-hidden@m">
            Agendar Arte
          </button>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: 'NumberProjects',
  data() {
    return {
      stats: [
        { number: '+100k', label: 'Projetos' },
        { number: '15', label: 'Designers' },
        { number: '120', label: 'Artes' }
      ]
    };
  }
};
</script>

<style scoped>
.text-position {
  align-content: rig;
}

.tx-title {
  width: 300px;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.uk-text-number {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 80px;
  line-height: 80px;
  color: #000000;
}

.uk-text-label {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000cc;
}

.button-enter {
  border: 2px solid #ffb800;
  border-radius: 12px;
  height: 48px;
  width: 226px;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 641px) {
  .button-enter {
    margin-top: 50px;
    width: 100%;
    margin-left: 30px;
  }

  .uk-text-large {
    font-size: 36px;
  }

  .tx-title {
    width: 256px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000cc;
  }

  .tx-title-mb {
    margin-top: -20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: #000000;
  }
}

@media (min-width: 641px) and (max-width: 960px) {
  .tx-title {
    width: 544px;
  }
}
</style>
