<template>
  <NavApp />
  <HeaderApp />
  <ChooseDraw />
  <ChooseAL />
  <NumberProjects />
  <Testimony />
  <RecentWorks />
  <CreateArt />
  <FooterApp />
</template>
  
  <script>
import NavApp from '../../components/Home/Nav/Nav.vue';
import HeaderApp from '../../components/Home/Header/Header.vue';
import ChooseDraw from '../../components/Home/ChooseDraw/ChooseDraw.vue';
import ChooseAL from '../../components/Home/ChooseAL/ChooseArteLegal.vue';
import NumberProjects from '../../components/Home/NumberProjects/NumberProjects.vue';
import Testimony from '../../components/Home/Testimony/Testimony.vue';
import RecentWorks from '../../components/Home/RecentWorks/RecentWorks.vue';
import CreateArt from '../../components/Home/CreateArt/CreateArt.vue';
import FooterApp from '../../components/Home/Footer/Footer.vue';

export default {
  name: 'App',
  components: {
    NavApp,
    HeaderApp,
    ChooseDraw,
    ChooseAL,
    NumberProjects,
    Testimony,
    RecentWorks,
    CreateArt,
    FooterApp
  }
};
</script>


  