<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <header>
    <section>
      <main class="uk-container uk-margin-dkt">
        <div
          class="uk-child-width-expand@s"
          uk-grid
          uk-height-match="target: > div > .uk-card"
        >
          <div>
            <h1 class="title-h1 uk-margin-large-top">
              Seu negócio merece uma arte legal
            </h1>
            <h2 class="title-h2">Agende seu horário com nossos designers</h2>
            <button class="button-primary button hide-mobile">
              Agendar Arte
            </button>
          </div>
          <div class="margin-mb-img img-container">
            <img class="size-img-mobile" :src="headerImg" alt="" />
          </div>
        </div>
        <div>
          <button
            class="button-primary button-primary-mb hide-mobile uk-visible@s uk-hidden@l"
          >
            Agendar Arte
          </button>
        </div>
      </main>
    </section>
  </header>
</template>

<script>
import headerImg from './headerImg.svg';

export default {
  name: 'HeaderVue',
  data() {
    return {
      headerImg: headerImg
    };
  }
};
</script>

<style scoped>
.uk-margin-dkt {
  margin-top: 50px;
}
.title-h1 {
  width: 511px;
  height: 237px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  line-height: 79.2px;
  font-size: 72px;
  color: #000000;
}

.uk-margin-top-img {
  margin-top: 90px;
}

.title-h2 {
  width: 511px;
  height: 29px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 28.8px;
  font-size: 24px;
  color: #00000099;
}

.button-primary {
  width: 226px;
  height: 48px;
  border-radius: 12px;
  padding: 12px, 24px, 12px, 24px;
  gap: 8px;
  border: none;
  background-color: #fdd935;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.margin-mb-img {
  margin-left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 641px) {
  .uk-margin-dkt {
    margin-top: 0px;
  }

  .size-img-mobile {
    width: 288px;
    height: 288px;
    display: flex;
    margin: 0 auto;
  }

  .title-h1 {
    font-size: 40px;
    line-height: 48px;
    width: 288px;
    height: 144px;
  }

  .title-h2 {
    width: 288px;
    height: 44px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .button {
    margin-top: 14px;
    margin-bottom: 14px;
    width: 100%;
  }
}

@media (min-width: 641px) and (max-width: 960px) {
  .uk-margin-dkt {
    margin-top: 0px;
  }

  .hide-mobile {
    display: none;
  }

  .title-h2 {
    width: 200px;
    height: 54px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }

  .title-h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    width: 264px;
  }

  .image-container {
    order: 2;
    margin-top: -200px;
  }

  .text-container {
    order: 1;
  }

  .button-primary-mb {
    width: 100%;
    height: 40px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    margin-top: 23px;
    align-items: center;
  }
}
</style>
