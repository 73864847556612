<template>
  <section>
    <main class="uk-container uk-margin-large-top">
      <div class="uk-text-center uk-margin-large-top">
        <h2 class="uk-heading-medium">
          <span class="uk-display-block">Eles confiam no Arte </span>
          <span class="uk-display-block">Legal</span>
        </h2>
      </div>

      <div class="uk-margin-large-top" uk-grid>
        <div
          v-for="(group, groupIndex) in cardGroups"
          :key="groupIndex"
          :class="[
            'uk-width-1-2@m uk-width-1-1@s',
            { 'uk-flex uk-flex-column': groupIndex === 0 }
          ]"
        >
          <div
            v-for="(card, index) in group"
            :key="index"
            class="uk-card uk-card-body uk-margin-bottom"
            :class="{
              'left-card': groupIndex === 0,
              'fixed-card': groupIndex === 1
            }"
            uk-height-match="target: > .uk-card"
          >
            <p class="uk-text-lead">
              {{ card.text }}
            </p>
            <div class="uk-margin-top uk-flex uk-flex-middle">
              <img
                :src="img_client"
                alt=""
                class="uk-border-circle uk-img-client"
              />
              <div>
                <div class="uk-text-name uk-margin-small-left">
                  {{ card.name }}
                </div>
                <div class="uk-text-company uk-margin-small-left">
                  {{ card.company }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>


<script>
import img_client from './img_client.svg';

export default {
  name: 'TestimonyVue',
  data() {
    return {
      img_client: img_client,
      cardGroups: [
        [
          {
            name: 'Aquilis Fernando',
            company: 'Zap Gráfica',
            text: 'Construímos uma rede de freelancers confiáveis ​​com os quais podemos contar quando precisamos que algo seja feito.'
          },
          {
            name: 'Aquilis Fernando',
            company: 'Zap Gráfica',
            text: 'Construímos uma rede de freelancers confiáveis ​​com os quais podemos contar quando precisamos que algo seja feito.'
          }
        ],
        [
          {
            name: 'Aquilis Fernando',
            company: 'Zap Gráfica',
            text: 'Construímos uma rede de freelancers confiáveis ​​com os quais podemos contar quando precisamos que algo seja feito. Construímos uma rede de freelancers confiáveis ​​com os quais podemos contar quando precisamos que algo seja feito. Construímos uma rede de freelancers confiáveis ​​com os quais podemos contar quando precisamos que algo seja feito. Construímos uma rede de freelancers confiáveis ​​com os quais podemos contar quando precisamos que algo seja feito. Construímos uma.'
          }
        ]
      ]
    };
  }
};
</script>


<style scoped>
.uk-display-block {
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 56px;
  line-height: 61.6px;
}

.uk-card {
  border: 1px solid #0000001f;
  border-radius: 16px;
}

.uk-text-name {
  color: #000000cc;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.uk-img-client {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.uk-text-lead {
  color: #000000cc;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.left-card:not(:last-child) {
  margin-bottom: 40px;
}

.fixed-card {
  height: 96%;
}

@media (max-width: 767px) {
  .uk-heading-medium {
    font-size: 24px;
    line-height: 38.4px;
  }

  .fixed-card {
    height: 590px;
  }
}
</style>


