<template>
  <nav class="uk-navbar-container uk-background-default" uk-sticky>
    <div class="uk-container">
      <div uk-navbar>
        <div class="uk-navbar-left">
          <ul class="uk-navbar-nav">
            <li class="li-title" @click="navigateToHome">Arte Legal</li>
            <li class="uk-active uk-margin-large-left search-container">
              <form class="uk-search uk-search-default">
                <input
                  class="uk-search-input font-nav"
                  type="search"
                  placeholder="Qual material você procura?"
                  aria-label="Qual material você procura?"
                />
              </form>
            </li>
          </ul>
        </div>
        <div class="uk-navbar-right desktop-menu">
          <ul class="uk-navbar-nav">
            <li
              v-for="(item, index) in menuItems"
              :key="index"
              :class="{
                underline: item.text === 'Categorias'
              }"
            >
              <a
                :href="item.link"
                class="uk-margin-left font-nav"
                @click="navigate(item)"
                >{{ item.text }}</a
              >
            </li>
          </ul>
          <button class="nav-button" @click="navigateToCreateAccount">
            Criar Conta
          </button>
        </div>
        <div class="uk-navbar-right mobile-menu">
          <a
            class="uk-navbar-toggle"
            uk-navbar-toggle-icon
            uk-toggle="target: #offcanvas-nav"
          ></a>
        </div>
      </div>
    </div>
  </nav>

  <div id="offcanvas-nav" uk-offcanvas="mode: push; overlay: true">
    <div class="uk-offcanvas-bar uk-flex uk-flex-column uk-flex-between">
      <div>
        <button class="uk-offcanvas-close" type="button" uk-close></button>
        <ul class="uk-nav uk-nav-default">
          <li
            v-for="(item, index) in offcanvasItems"
            :key="index"
            :class="{
              underline: item.text === 'Categorias'
            }"
          >
            <a :href="item.link" @click="navigate(item)">{{ item.text }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import search_icon_url from './search-icon.svg';

export default {
  name: 'HeaderVue',
  data() {
    return {
      search_icon_url: search_icon_url,
      menuItems: [
        { text: 'Categorias', link: '#' },
        { text: 'Entrar', link: '#' }
      ],
      offcanvasItems: [
        { text: 'Entrar', link: '#' },
        { text: 'Criar Conta', link: '#' },
        { text: 'Categorias', link: '#' },
        { text: 'Sobre', link: '#' },
        { text: 'Fale Conosco', link: '#' }
      ]
    };
  },
  methods: {
    navigateToHome() {
      this.$router.push('/');
    },
    navigateToLogin() {
      event.preventDefault();
      this.$router.push('/login');
    },
    navigateToCreateAccount() {
      event.preventDefault();
      this.$router.push('/create-account');
    },
    navigate(item) {
      if (item.text === 'Entrar') {
        this.navigateToLogin();
      } else {
        this.$router.push(item.link);
      }
    }
  }
};
</script>

<style scoped>
.li-content {
  color: #000000;
}

.uk-background-default {
  background-color: white;
}

.font-nav {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  color: #000000;
  text-transform: none;
}

.uk-search-input {
  width: 600px;
  padding: 12px;
  border-radius: 16px;
  background: url('./search-icon.svg') no-repeat;
  background-position: 10px center;
  padding-left: 40px;
  cursor: pointer;
}

.li-title {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin-top: 5px;
  cursor: pointer;
}

.nav-button {
  font-weight: 600;
  font-size: 16px;
  width: 140px;
  height: 48px;
  border: none;
  border-radius: 12px;
  padding: 12px 24px;
  background-color: #fdd935;
  cursor: pointer;
}

.nav-button:hover {
  cursor: pointer;
}

.search-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .underline a {
    width: 100%;
    border-bottom: 1px solid #0000001f;
    border-top: 1px solid #0000001f;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .search-container {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  .desktop-menu {
    display: none;
  }

  .uk-search-input {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .mobile-menu {
    display: none;
  }
}

#offcanvas-nav .uk-offcanvas-bar {
  background: white;
  padding: 20px;
  height: 330px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#offcanvas-nav .uk-nav li {
  margin: 20px 0;
}

#offcanvas-nav .uk-nav a {
  color: black;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.uk-offcanvas-close {
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
  color: black;
}
</style>
